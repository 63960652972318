<template>
  <div class="view-wrap">
    <el-form :model="searcForm" class="F">
      <el-form-item label="日期范围:" style="width: 450px">
        <el-date-picker
          v-model="pickerDate"
          type="daterange"
          range-separator="至"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          :picker-options="pickerOptions"
          end-placeholder="结束日期"
          :clearable="false"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="客户:"
        style="width: 280px; margin-right: 15px"
        label-width="60px"
      >
        <div style="position: relative" @click="customClick">
          <el-input v-model="checkCustomName"></el-input>
          <span
            style="position: absolute; right: 10px; top: -3px; cursor: pointer"
            >...</span
          >
        </div>
      </el-form-item>
      <el-form-item label="商品:" style="width: 330px">
        <el-select v-model="searcForm.productIds" multiple placeholder="">
          <el-option
            v-for="item in productList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="仓库:" style="width: 330px">
        <el-select v-model="searcForm.wmsIds" multiple placeholder="">
          <el-option
            v-for="item in storehouse"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="F">
          <el-button type="primary" size="mini" @click="searchForm"
            >查询</el-button
          >
          <el-button type="primary" @click="handleDownload" plain
            >导出</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <div class="footBtn">
      <div></div>
      <div></div>
    </div>
    <div class="bigbox">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="customerCode"
          label="客户"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.customerName }}
            <span v-show="scope.row.customerName != '小计' && scope.row.customerName != '合计'">(</span>
            {{ scope.row.customerCode }}
            <span v-show="scope.row.customerName != '小计' && scope.row.customerName != '合计'">)</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="code"
          label="商品编号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="productName"
          label="商品名称"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="spec" label="规格" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.spec ? scope.row.spec : "--" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="barCode"
          label="条形码"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.barCode ? scope.row.barCode : "--" }}
          </template>
        </el-table-column>
        <el-table-column label="销售出库" align="center">
          <el-table-column prop="orderNum" label="数量" align="center">
            <template slot-scope="scope">
              {{ scope.row.orderNum ? scope.row.orderNum : "--" }}
            </template>
          </el-table-column>
          <el-table-column label="金额" align="center" prop="orderPrice">
            <template slot-scope="scope">
              {{
                scope.row.orderPrice ? scope.row.orderPrice.toFixed(2) : "--"
              }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="销售退货" align="center">
          <el-table-column prop="orderRefundNum" label="数量" align="center">
            <template slot-scope="scope">
              {{ scope.row.orderRefundNum ? scope.row.orderRefundNum : "--" }}
            </template>
          </el-table-column>
          <el-table-column label="金额" align="center" prop="orderRefundPrice">
            <template slot-scope="scope">
              {{
                scope.row.orderRefundPrice
                  ? scope.row.orderRefundPrice.toFixed(2)
                  : "--"
              }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="合计" align="center">
          <el-table-column prop="sumNum" label="数量" align="center">
          </el-table-column>
          <el-table-column label="金额" align="center" prop="sumPrice">
            <template slot-scope="scope">
              {{
                scope.row.sumPrice
                  ? Number(scope.row.sumPrice).toFixed(2)
                  : "--"
              }}
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <!-- <table
        border="1"
        cellpadding="0"
        cellspacing="0"
        style="
          position: absolute;
          bottom: -48px;
          right: 0;
          border-color: #efffff;
          background-color: #fff;
          z-index: 1;
        "
      >
        <tr style="height: 48px">
          <td
            style="
              width: 149px;
              text-align: center;
              font-weight: bold;
              border-right: none;
            "
          >
            {{ totalNum.supplierName }}
          </td>
          <td style="width: 150px; text-align: center; border-right: none"></td>
          <td style="width: 150px; text-align: center; border-right: none"></td>
          <td style="width: 120px; text-align: center; border-right: none"></td>
          <td style="width: 120px; text-align: center; border-right: none"></td>
          <td style="width: 83px; text-align: center; border-right: none">
            {{ totalNum.num }}
          </td>
          <td style="width: 83px; text-align: center; border-right: none">
            {{ totalNum.price.toFixed(2) }}
          </td>
          <td style="width: 83px; text-align: center; border-right: none">
            {{ totalNum.refNum }}
          </td>
          <td style="width: 83px; text-align: center; border-right: none">
            {{ totalNum.refPrice.toFixed(2) }}
          </td>
          <td style="width: 83px; text-align: center; border-right: none">
            {{ totalNum.sumNum }}
          </td>
          <td style="width: 83px; text-align: center; border-right: none">
            {{ totalNum.sumPrice.toFixed(2) }}
          </td>
        </tr>
      </table> -->
    </div>
    <OpenAccount :info="info"></OpenAccount>
    <SelectCustom :info="cusInfo" @SelectCustom="SelectCustom"></SelectCustom>
  </div>
</template>

<script>
import { datatimestatus } from "@/until/auth";
import { getProductList, getTheWarehouseList } from "@/api/procurementSummary";
import SelectCustom from "@/components/SelectCustom";
import { getSummaryCustomer } from "@/api/sale";
import { getConfigBill } from "@/api/user";
import OpenAccount from "@/views/common/OpenAccount";
export default {
  components: {
    OpenAccount,
    SelectCustom,
  },
  data() {
    return {
      checkCustomName: [],
      cusInfo: {
        isShow: false,
      },
      openAccountFlag: true,
      info: {
        show: false,
      },
      tableDatas: [], //导出组装的数据
      totalNum: {
        supplierName: "合计",
        num: 0,
        price: 0,
        refNum: 0,
        refPrice: 0,
        sumNum: 0,
        sumPrice: 0,
      },
      supTotal: {
        supplierName: "小计",
        num: 0,
        price: 0,
        refNum: 0,
        refPrice: 0,
        sumNum: 0,
        sumPrice: 0,
      },
      storehouse: [], //仓库列表
      productList: [], //商品列表
      tableData: [], //表格数据
      suppliers: [], //供应商
      pickerOptions: null,
      pickerDate: [], //日期范围
      searcForm: {
        customerIds: "",
        productIds: "",
        wmsIds: "",
        startDate: "",
        endDate: "",
        pageNum: 1,
        pageSize: 10,
      }, //搜索条件
    };
  },
  created() {
    this.getConfigBills();
    this.datatimestatus();
    this.getDate();
    this.getList();
    this.getProductList();
    this.getTheWarehouseList();
  },

  methods: {
    SelectCustom(list) {
      this.checkCustomName = list.map((item) => item.name).join(",");
      this.searcForm.customerIds = list.map((item) => item.id).join(",");
    },
    customClick() {
      this.cusInfo.isShow = true;
    },
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
          this.openAccountFlag = false;
        }
      }
    },
    // 导出单个表头
    handleDownload() {
      import("@/until/Export2Excel").then((excel) => {
        const multiHeader = [
          [
            "客户",
            "商品编号",
            "商品名称",
            "规格",
            "条形码",
            "销售出库",
            "",
            "销售退货",
            "",
            "合计",
            "",
          ],
        ]; // 前两行的表头数据，二维数组，不够的用空白补全
        const header = [
          "",
          "",
          "",
          "",
          "",
          "数量",
          "金额",
          "数量",
          "金额",
          "数量",
          "金额",
        ]; // 最后一行的表头数据
        const filterVal = [
          "customerName",
          "code",
          "productName",
          "spec",
          "barCode",
          "orderNum",
          "orderPrice",
          "orderRefundNum",
          "orderRefundPrice",
          "sumNum",
          "sumPrice",
        ];
        // 这里要注意 header  和 filterVal 的数组长度一定要相等，否则会报错
        const list = this.tableData;
        const data = this.formatJson(filterVal, list);
        const merges = [
          "A1:A2",
          "B1:B2",
          "C1:C2",
          "D1:D2",
          "E1:E2",
          "F1:G1",
          "H1:I1",
          "J1:K1",
        ]; // 合并单元格的数据，如何合并参考上面图片讲解
        excel.export_json_to_excel({
          multiHeader,
          header,
          merges,
          data,
          filename: "销售汇总表(按客户)",
        });
      });
    },
    // 拼接数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    //获取仓库列表
    async getTheWarehouseList() {
      let res = await getTheWarehouseList();
      if (res.code == 1) {
        this.storehouse = res.data.records;
      }
    },
    //获取商品列表
    async getProductList() {
      let res = await getProductList();
      if (res.code == 1) {
        this.productList = res.data.records;
      }
    },
    //查询按钮
    searchForm() {
      this.getList();
    },
    //获取列表
    async getList() {
      this.totalNum = {
        supplierName: "合计",
        num: 0,
        price: 0,
        refNum: 0,
        refPrice: 0,
        sumNum: 0,
        sumPrice: 0,
      };
      let params = {
        customerIds: this.searcForm.customerIds,
        productIds: this.searcForm.productIds,
        wmsIds: this.searcForm.wmsIds,
        startDate: this.pickerDate[0],
        endDate: this.pickerDate[1],
      };

      let res = await getSummaryCustomer(params);
      if (res.code == 1) {
        let arr = res.data;
        arr.forEach((item, index) => {
          item.list.forEach((items, indexs) => {
            items.customerId = item.id;
            items.customerName = item.name;
            items.customerCode = item.code;
            items.sumNum = items.orderNum - items.orderRefundNum;
            items.sumPrice = (items.orderPrice - items.orderRefundPrice) / 100;
          });
        });
        let arr2 = arr.map((item) => item.list);

        arr2.forEach((item) => {
          let obj = {
            customerName: "小计",
            orderNum: 0,
            orderPrice: 0,
            orderRefundNum: 0,
            orderRefundPrice: 0,
            sumNum: 0,
            sumPrice: 0,
          };
          let onceNum = 0; //小计只能被push一次
          item.forEach((items) => {
            onceNum++;
            items.orderPrice = items.orderPrice / 100;
            items.orderRefundPrice = items.orderRefundPrice / 100;
            obj.orderNum += items.orderNum;
            obj.orderPrice += items.orderPrice;
            obj.orderRefundNum += items.orderRefundNum;
            obj.orderRefundPrice += items.orderRefundPrice;
            obj.sumNum += obj.orderNum - obj.orderRefundNum;
            obj.sumPrice += obj.orderPrice - obj.orderRefundPrice;
            if (onceNum <= 1) {
              item.push(obj);
            }
          });
        });
        console.log(arr2);
        arr2.forEach((item) => {
          item.forEach((items) => {
            this.tableData.push(items);
          });
        });
        
        let obj2 = {
          customerName: "合计",
          orderNum: 0,
          orderPrice: 0,
          orderRefundNum: 0,
          orderRefundPrice: 0,
          sumNum: 0,
          sumPrice: 0,
        };
        let onceNum2 = 0; //合计只能被push一次
        this.tableData.forEach((item) => {
          
          if(item.customerName == "小计"){
            onceNum2++
            obj2.orderNum += item.orderNum;
            obj2.orderPrice += item.orderPrice;
            obj2.orderRefundNum += item.orderRefundNum;
            obj2.orderRefundPrice += item.orderRefundPrice;
            obj2.sumNum += obj2.orderNum - obj2.orderRefundNum;
            obj2.sumPrice += obj2.orderPrice - obj2.orderRefundPrice;
            if (onceNum2 <= 1) {
              this.tableData.push(obj2);
            }
          }
        });
        console.log(this.tableData);
      }
    },

    datatimestatus() {
      this.pickerOptions = datatimestatus();
    },
    // 默认时间
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      if (this.openAccountFlag) {
        var reg = sessionStorage.getItem("createDate").split(" ")[0];
        var defaultDate = [`${reg}`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${reg}`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      } else {
        var defaultDate = [`${year}-${month}-01`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${year}-${month}-01`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      }
      this.pickerDate = defaultDate;
      // this.getData();
    },
  },
  watch: {
    pickerDate(val) {
      if (!val) {
        this.searcForm.startDate = "";
        this.searcForm.endDate = "";
      } else {
        this.searcForm.startDate = val[0];
        this.searcForm.endDate = val[1];
      }
    },
  },
};
</script>

<style scoped>
.bigbox {
  position: relative;
}
.F {
  display: flex;
  flex-wrap: wrap;
}
.view-wrap >>> th .cell {
  font-weight: bold !important;
  color: #606266 !important;
}
.view-wrap >>> .el-select__tags {
  position: absolute;
  line-height: normal;
  white-space: normal;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.view-wrap >>> .el-select__tags {
  overflow: hidden !important;
}
.footBtn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.view-wrap >>> .el-table__footer-wrapper tbody td {
  color: #000;
  font-weight: bold;
}
.view-wrap >>> .el-table__footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 18%;
}
.view-wrap {
  position: relative;
}
.export-btn {
  width: 60px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #409eff;
  text-align: center;
  color: #409eff;
  font-size: 12px !important;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}
</style>